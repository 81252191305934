import Service from '@ember/service';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Evented from '@ember/object/evented';
import AuthService from './auth';
import {
  CreateOrganizationPayload,
  Organization,
  OrganizationInviteReasonEnum,
  OrganizationInviteStatusEnum,
  OrganizationsApi
} from './api-client';
import { observes } from '@ember-decorators/object';
import { BASE_URL } from './baseurl';

export default class OrganizationService extends Service.extend(Evented) {
  @service('auth') authService!: AuthService;

  currentOrganization: Organization | undefined;
  initLoading = true;

  @computed('authService.apiConfig')
  get organizationApi() {
    return new OrganizationsApi(this.authService.apiConfig, BASE_URL);
  }

  async createOrganization(payload: CreateOrganizationPayload) {
    const org = await this.organizationApi.organizationControllerCreate(payload);
    this.set('currentOrganization', org.data);
    return org.data;
  }

  @observes('authService.currentUser')
  async loadOrganization() {
    if (!this.authService.currentUser) {
      this.set('currentOrganization', undefined);
      return;
    }

    const organization = await this.organizationApi.organizationControllerGetMyOrganization();
    this.set('currentOrganization', organization.data);
    this.set('initLoading', false);
  }

  async requestOrganizationJoin(organization: Organization) {
    await this.organizationApi.organizationControllerJoinOrganization(organization.id);
  }

  async leaveOrganization() {
    await this.organizationApi.organizationControllerLeaveMyOrganization();
    this.set('currentOrganization', undefined);
  }

  async getInvites(reason?: OrganizationInviteReasonEnum, status?: OrganizationInviteStatusEnum) {
    return await this.organizationApi.organizationControllerGetMyInvites(reason, status);
  }

  async inviteUser(email: string) {
    if (!this.currentOrganization) return;
    const org = await this.organizationApi.organizationControllerInvite(this.currentOrganization.id, email);
    this.set('currentOrganization', org.data);
    return org.data;
  }

  constructor() {
    super(...arguments);
    this.loadOrganization();
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  // eslint-disable-next-line no-unused-vars
  interface Registry {
    organization: OrganizationService;
  }
}

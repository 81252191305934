import Component from '@ember/component';
import { Address, CreateOrganizationPayload, Organization } from '../../services/api-client';
import { inject as service } from '@ember/service';
import AuthService from '../../services/auth';
import { computed, action } from '@ember/object';
import { classNames } from '@ember-decorators/component';
import { validatePresence } from 'ember-changeset-validations/validators';
import lookupValidator from 'ember-changeset-validations';
import Changeset from 'ember-changeset';

@classNames('contents')
export default class CompanyCard extends Component.extend({
  // anything which *must* be merged to prototype here
}) {
  @service('auth') authService!: AuthService;

  org?: Organization;

  addressValidations = {
    street: [validatePresence(true)],
    city: [validatePresence(true)],
    zip: [validatePresence(true)],
    country: [validatePresence(true)]
  };

  orgValidations = {
    name: [validatePresence(true)],
    taxId: [validatePresence(true)]
  };

  orgChangeset: any;
  addressChangeset: any;

  createAddress: Address = {
    street: '',
    city: '',
    zip: '',
    country: '',
    firstName: '',
    lastName: '',
    phone: ''
  } as Address;

  createOrg: Organization = {
    name: '',
    taxId: ''
  } as Organization;

  onLeave?: () => void;

  // eslint-disable-next-line no-unused-vars
  onCreate?: (payload: CreateOrganizationPayload) => void;

  constructor() {
    super(...arguments);
  }

  @action
  leaveCompany() {
    if (this.onLeave) {
      this.onLeave();
    }
  }

  @action
  async submit() {
    this.set(
      'addressChangeset',
      new Changeset(this.createAddress, lookupValidator(this.addressValidations), this.addressValidations)
    );
    await this.addressChangeset.validate();

    this.set('orgChangeset', new Changeset(this.createOrg, lookupValidator(this.orgValidations), this.orgValidations));
    await this.orgChangeset.validate();

    if (!this.addressChangeset.get('isValid') || !this.orgChangeset.get('isValid')) {
      return;
    }

    const dto: CreateOrganizationPayload = {
      name: this.createOrg.name,
      taxId: this.createOrg.taxId,
      addresses: [this.createAddress]
    };

    if (this.onCreate) {
      this.onCreate(dto);
    }
  }

  @computed('org')
  get initials() {
    if (!this.org) return '';
    if (this.org?.name.includes(' ')) {
      return this.org.name.charAt(0).toUpperCase() + this.org?.name.charAt(1).toUpperCase();
    } else {
      return this.org.name.charAt(0).toUpperCase();
    }
  }
}
